<template>
<div class="footer">
  <div class="footer-content-hd">
    <div class="hd-left">
      <div class="company-title">

      </div>
      <div class="company-info telephone">
        商务合作：0755-2348 0450
      </div>
      <div class="company-info address">
        地址：深圳市福田区莲花街道福中社区彩田路5015号中银大厦B座9E
      </div>
      <div class="company-info email">
        邮箱：support@youweiwork.com
      </div>
    </div>
    <div class="hd-right">
      <div class="wx-qr-left">
        <div class="icon-wx">
          <img alt="" src="./icon-wx.png">
        </div>
        <div class="wx-list">
          <div class="item"
            @click="changeQRCode(QR_CODE_TYPE.applet)"
            :class="{on: QR_CODE_TYPE.applet.value === QRCode.value}"
          >
            优职派小程序<i class="el-icon-arrow-right"></i>
          </div>
          <div class="item"
            @click="changeQRCode(QR_CODE_TYPE.wx)"
            :class="{on: QR_CODE_TYPE.wx.value === QRCode.value}"
          >
            优职派公众号<i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="wx-qr-code">
        <img :src="QRCode.img" alt="">
      </div>
    </div>
  </div>
  <div class="footer-content_bd">
    <p>
<!--      <a href="https://s.youxiuqn.com/ICP.png" target="_blank">-->
<!--        增值电信业务经营许可证：粤B2-20181336-->
<!--      </a>-->
<!--      &nbsp;&nbsp;-->
      <a href="https://beian.miit.gov.cn" target="_blank">
        {{$baseInfo.beian}}
      </a>
    </p>
    <p style="margin-top: 10px">
      四达铭铺（深圳）投资控股有限公司版权所有Copyright &copy; 2018-2023 YOUZHIPAI.All Rights Reserved
    </p>
  </div>
</div>
</template>

<script>
const QR_CODE_TYPE = {
  applet: {
    value: 1,
    img: require('./applet_qr.jpg')
  },
  wx: {
    value: 2,
    img: require('./wx_qr.jpg')
  }
}
export default {
  name: 'footerCopyright',
  data () {
    return {
      QR_CODE_TYPE,
      QRCode: QR_CODE_TYPE.applet
    }
  },
  methods: {
    changeQRCode(type) {
      this.QRCode = type
    }
  }
}
</script>

<style scoped lang="less">
  @import "~@/styles/customMixin.less";
.footer {
  z-index: 1;
  position: absolute;
  width: 100%;
  overflow: hidden;
  left: 0;
  bottom: 0;
  background: url("./footerBg.png");
  .footer-content-hd{
    width: 1200px;
    margin: 0 auto ;
    padding: 60px 0 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hd-left{
      flex: 1;
      .company-title{
        color: #C0C4CC;
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: bold;
      }
      .company-info{
        padding-left: 18px;
        position: relative;
        color: #999999;
        & + .company-info {
          margin-top: 14px;
        }
        &.telephone {
          color: @primary-color;
          font-size: 20px;
          font-weight: bold;
          &:before {
            background-color: @primary-color;
          }
        }
        &:before {
          content: '';
          width: 2px;
          height: 14px;
          background-color: #999999;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .hd-right{
      width: 508px;
      box-sizing: border-box;
      border-left: 1px solid #444444;
      padding-left: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wx-qr-left{
        img{
          width: 122px;
          height: 55px;
        }
      }
      .wx-list {
        margin-top: 20px;
        color: #999999;
        font-size: 16px;
        .item{
          display: flex;
          align-items: center;
          padding-left: 12px;
          position: relative;
          cursor: pointer;
          & + .item {
            margin-top: 16px;
          }
          &.on {
            color: @primary-color;
            &:before {
              background-color: @primary-color;
            }
          }
          &:before {
            content: '';
            width: 2px;
            height: 14px;
            background-color: #999999;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .el-icon-arrow-right{
          font-size: 14px;
          margin-right: 9px;
        }
      }
      .wx-qr-code{
        width: 184px;
        height: 184px;
        background-color: #2D3037;
        padding: 15px;
        box-sizing: border-box;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer-content_bd{
    background-color: #191C23;
    padding: 20px 0;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    color: #666666;
    a{
      color: #666666;
    }
  }

}
</style>
